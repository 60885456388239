/*


exit = "exit"
animate = "animate"
initial = "initial"

const variable = {
    exit : {

    }, 
    animate : {

    }, 
    initial : {

    }, 
}

*/

export const invitation__background__anim = {
    exit : {
        opacity : 0
    }, 
    animate : {
        opacity : 1
    }, 
    initial : {
        opacity : 0
    }, 
}