import React, {useState, useEffect} from 'react'
import {AnimatePresence, motion, useAnimationControls} from 'framer-motion'
import {ReactComponent as Logo} from './assets/logo.svg'
import {ReactComponent as BackgroundLetter} from './assets/background_letter.svg' ; 
import {ReactComponent as FrontLetter} from './assets/front_letter.svg' ; 
import {ReactComponent as Letter} from './assets/letter.svg'

import './styles/style.css';
import { invitation__background__anim } from './animations/letter';

function App() {
  const [open, setOpen] = useState(false)
  const controls = useAnimationControls()


  const sequence = async () => {
    await controls.start({ 
            y: -1000, 
            x : 'calc(-50% + 10px)', 
            transition : {
              type : "linear"
            }

    })
    await controls.start({ 
      y: '-50%',
      top : '50%',
      zIndex : 1000, 
      position : "fixed",  
      x : 'calc(-50% + 10px)', 
      transition : {
        type : "linear", 
        duration : 0.6
      }
      })
      setOpen(true)
  }



  return (
    <div className = 'app'>
        <div className = 'app__introduction'>
            <div className = "logo">
                <Logo />
            </div>
            <div className='content'>
                <h1>Tu as reçu une invitation !</h1>
                <p>Clique sur l'enveloppe pour découvrir le contenu.</p>
            </div>
        </div>

        <div className='app__container'>
          <div className='letter' 
            onClick={() => !open ? sequence() : null}
          >
              <AnimatePresence>
                {
                  open ? 
                    <motion.div 
                      exit = "exit"
                      animate = "animate"
                      initial = "initial"
                      variants={invitation__background__anim}
                      className='background__invitation'
                    >

                    </motion.div>

                  : 
                    null
                }

              </AnimatePresence>
              <AnimatePresence>
                <motion.div 
                  className='letter__front'
                  exit = "exit"
                  animate = "animate"
                  initial = "initial"
                >
                  <FrontLetter />
                </motion.div>
                </AnimatePresence>

                <AnimatePresence>
                <motion.div 
                  animate = {controls}
                  className='letter__content'>
                  <Letter />
                </motion.div>
                </AnimatePresence>

                <AnimatePresence>
                <motion.div 
                  exit = "exit"
                  animate = "animate"
                  initial = "initial"
                  className='letter__background'>
                    <BackgroundLetter />
                </motion.div>
                </AnimatePresence>
            </div>

          </div>

    </div>
  );
}

export default App;
